import '@assets/scss/pages/project.scss';

import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';

// components
import Layout from '@src/layouts';
import Video from '@components/atoms/Video';
import Blockquote from '@components/molecules/Blockquote';
// import ButtonExternal from '@components/atoms/buttons/ButtonExternal';
import ButtonSecondary from '@components/atoms/buttons/ButtonSecondary';
import Column from '@components/organisms/Column';
// import Cta from '@components/molecules/Cta';
import Gallery from '@components/organisms/Gallery';
import HeroProject from '@components/molecules/heros/HeroProject';
import Image from '@components/atoms/Image';
// import ItemArticle from '@components/molecules/items/ItemArticle';
import ListText from '@components/organisms/ListText';

// --------------------------------
// #region data
// --------------------------------

const language = 'en';

const pageClass = 'page-project';

const accent_color = '#3CD5AF';
const footerRelatedLinks = [
	{
		title: 'Ackermann typography',
		url: '/en/projects/ackermann-typography/',
	},
];

// --------------------------------
// #endregion
// --------------------------------

const ProjectAckBrandPage = (props) => {
	const data = useStaticQuery(
		graphql`
			query {
				heroImage: file(
					relativePath: {
						eq: "projects/ackermann/branding/img-brand-cover.jpg"
					}
				) {
					childImageSharp {
						fluid(maxWidth: 1920, quality: 100) {
							...GatsbyImageSharpFluid_withWebp
						}
					}
				}
				heroImageMobile: file(
					relativePath: {
						eq: "projects/ackermann/branding/img-brand-cover-mobile.jpg"
					}
				) {
					childImageSharp {
						fluid(maxWidth: 1920, quality: 100) {
							...GatsbyImageSharpFluid_withWebp
						}
					}
				}
				image_1: file(
					relativePath: {
						eq: "projects/ackermann/branding/img-brand-brandDNA-EN.png"
					}
				) {
					childImageSharp {
						fluid(maxWidth: 1000, quality: 100) {
							...GatsbyImageSharpFluid_withWebp
						}
					}
				}
				image_2: file(
					relativePath: {
						eq: "projects/ackermann/branding/img-brand-logo-01.jpg"
					}
				) {
					childImageSharp {
						fluid(maxWidth: 1920, quality: 100) {
							...GatsbyImageSharpFluid_withWebp
						}
					}
				}
				image_3: file(
					relativePath: {
						eq: "projects/ackermann/branding/img-brand-logo-02.jpg"
					}
				) {
					childImageSharp {
						fluid(maxWidth: 1920, quality: 100) {
							...GatsbyImageSharpFluid_withWebp
						}
					}
				}
				image_4: file(
					relativePath: {
						eq: "projects/ackermann/branding/img-brand-duo-christophe-texture.jpg"
					}
				) {
					childImageSharp {
						fluid(maxWidth: 1920, quality: 100) {
							...GatsbyImageSharpFluid_withWebp
						}
					}
				}
				image_5: file(
					relativePath: {
						eq: "projects/ackermann/branding/img-brand-duo-farine-texture.jpg"
					}
				) {
					childImageSharp {
						fluid(maxWidth: 1920, quality: 100) {
							...GatsbyImageSharpFluid_withWebp
						}
					}
				}
				image_6: file(
					relativePath: {
						eq: "projects/ackermann/branding/img-brand-duo-flute-texture.jpg"
					}
				) {
					childImageSharp {
						fluid(maxWidth: 1920, quality: 100) {
							...GatsbyImageSharpFluid_withWebp
						}
					}
				}
				image_7: file(
					relativePath: {
						eq: "projects/ackermann/branding/img-brand-patisserie.jpg"
					}
				) {
					childImageSharp {
						fluid(maxWidth: 1920, quality: 100) {
							...GatsbyImageSharpFluid_withWebp
						}
					}
				}
				image_8: file(
					relativePath: {
						eq: "projects/ackermann/branding/img-brand-packaging-01.jpg"
					}
				) {
					childImageSharp {
						fluid(maxWidth: 1920, quality: 100) {
							...GatsbyImageSharpFluid_withWebp
						}
					}
				}
				image_9: file(
					relativePath: {
						eq: "projects/ackermann/branding/img-brand-packaging-02.jpg"
					}
				) {
					childImageSharp {
						fluid(maxWidth: 1920, quality: 100) {
							...GatsbyImageSharpFluid_withWebp
						}
					}
				}
				image_10: file(
					relativePath: {
						eq: "projects/ackermann/branding/img-brand-packaging-big.jpg"
					}
				) {
					childImageSharp {
						fluid(maxWidth: 1920, quality: 100) {
							...GatsbyImageSharpFluid_withWebp
						}
					}
				}
				image_11: file(
					relativePath: {
						eq: "projects/ackermann/branding/img-brand-pain.jpg"
					}
				) {
					childImageSharp {
						fluid(maxWidth: 1920, quality: 100) {
							...GatsbyImageSharpFluid_withWebp
						}
					}
				}
				image_12: file(
					relativePath: {
						eq: "projects/ackermann/branding/img-brand-chocololat.jpg"
					}
				) {
					childImageSharp {
						fluid(maxWidth: 1920, quality: 100) {
							...GatsbyImageSharpFluid_withWebp
						}
					}
				}
				image_13: file(
					relativePath: {
						eq: "projects/ackermann/branding/img-brand-papier-soie.jpg"
					}
				) {
					childImageSharp {
						fluid(maxWidth: 1920, quality: 100) {
							...GatsbyImageSharpFluid_withWebp
						}
					}
				}
				image_14: file(
					relativePath: {
						eq: "projects/ackermann/branding/img-brand-team-duo.jpg"
					}
				) {
					childImageSharp {
						fluid(maxWidth: 1920, quality: 100) {
							...GatsbyImageSharpFluid_withWebp
						}
					}
				}
				image_15: file(
					relativePath: {
						eq: "projects/ackermann/branding/img-brand-team-grille.jpg"
					}
				) {
					childImageSharp {
						fluid(maxWidth: 1920, quality: 100) {
							...GatsbyImageSharpFluid_withWebp
						}
					}
				}
				image_16: file(
					relativePath: {
						eq: "projects/ackermann/branding/img-brand-duo-patisserie-pain.jpg"
					}
				) {
					childImageSharp {
						fluid(maxWidth: 1920, quality: 100) {
							...GatsbyImageSharpFluid_withWebp
						}
					}
				}
				image_17: file(
					relativePath: {
						eq: "projects/ackermann/branding/img-brand-smart.jpg"
					}
				) {
					childImageSharp {
						fluid(maxWidth: 1920, quality: 100) {
							...GatsbyImageSharpFluid_withWebp
						}
					}
				}
				image_18: file(
					relativePath: {
						eq: "projects/ackermann/branding/img-brand-sac.jpg"
					}
				) {
					childImageSharp {
						fluid(maxWidth: 1920, quality: 100) {
							...GatsbyImageSharpFluid_withWebp
						}
					}
				}
				image_19: file(
					relativePath: {
						eq: "projects/ackermann/branding/img-brand-carte-visite.jpg"
					}
				) {
					childImageSharp {
						fluid(maxWidth: 1920, quality: 100) {
							...GatsbyImageSharpFluid_withWebp
						}
					}
				}
				image_20: file(
					relativePath: {
						eq: "projects/ackermann/branding/img-brand-affiche-typo-01.jpg"
					}
				) {
					childImageSharp {
						fluid(maxWidth: 1920, quality: 100) {
							...GatsbyImageSharpFluid_withWebp
						}
					}
				}
				image_21: file(
					relativePath: {
						eq: "projects/ackermann/branding/img-brand-affiche-typo-02.jpg"
					}
				) {
					childImageSharp {
						fluid(maxWidth: 1920, quality: 100) {
							...GatsbyImageSharpFluid_withWebp
						}
					}
				}
				image_22: file(
					relativePath: {
						eq: "projects/ackermann/branding/img-link-typo-EN.jpg"
					}
				) {
					childImageSharp {
						fluid(maxWidth: 1920, quality: 100) {
							...GatsbyImageSharpFluid_withWebp
						}
					}
				}
			}
		`
	);

	return (
		<Layout
			language={language}
			pageClass={pageClass}
			footerRelatedLinks={footerRelatedLinks}
			accentColor={accent_color}
			location={props.location}
			translations={[
				{
					uri: '/fr/projets/ackermann-branding/',
					lang: 'fr',
				},
			]}
			title="Ackermann Branding - By Superhuit"
		>
			<HeroProject
				breadcrumb={{
					title: 'Work',
					link: '/en/projects/',
				}}
				title="Ackermann"
				image={{
					...data.heroImage.childImageSharp,
				}}
				imageMobile={{
					...data.heroImageMobile.childImageSharp,
				}}
			/>
			<section className="grid grid-content" aria-label="Project">
				<div className="project-intro" data-animation-page>
					<div className="project-summary text-content">
						<p className="p-medium">
							Transforming raw material into a tasty piece of art.
							This is what Ackermann's does.
						</p>

						<ListText
							className="element-spacing"
							title="Our services"
							list={[
								'Brand positioning',
								'Branding',
								'Visual identity',
								'Packaging',
								'Typography',
							]}
						/>
					</div>
					<div className="project-description text-content">
						<h3>The challenge</h3>
						<p>
							Christophe Ackermann: artisan baker, pastry chef and
							Swiss chocolatier in the Yverdon region known for
							winning local and international awards represents
							the third generation of bakers. After 10 years of
							developing the business, it was time for him to
							embark on a new chapter sparked by a desire to
							redesign his brand.
						</p>

						<h3>Our answer</h3>
						<p>
							The art of baking is ancestral: it is expressed in
							the craft of working with shapes and materials. We
							identified the markers that make Ackermann a unique
							bakery. By first imaging its future, we were then
							able to set about bringing this to life in a visual
							sense.
						</p>

						<h3>Go further</h3>
						<ButtonSecondary
							href="/en/projects/ackermann-typography/"
							text="Ackermann handcrafted typography"
							data-target
						/>
					</div>
				</div>

				<div className="box" data-animation-page>
					<div className="list-two-columns align-items-center justify-normal">
						<div className="text-content">
							<h3>The brand's DNA</h3>
							<p>
								Art, traditional craftsmanship and humanity are
								three strong vectors of Ackermann's identity
								that the visual identity must transmit.
							</p>
						</div>
						<Image
							{...data.image_1.childImageSharp}
							alt="The brand's DNA"
						/>
					</div>
				</div>

				<Column modifiers="left">
					<div className="column__media text-content">
						<Image {...data.image_2.childImageSharp} alt="Logo" />
						<Image {...data.image_3.childImageSharp} alt="Logo" />
						<Video src="e7a1e2bc6fa54352f5b909beeecf43b0" alt="" />
					</div>

					<div className="column__text column__sticky text-content">
						<h3>Both traditional and progressive</h3>
						<p>
							The first expression of the repositioning can be
							seen in the lettering design of the Ackermann logo,
							which aims to create a distinguishing symbol that
							represents the brand's DNA.
						</p>
						<p>
							The logo’s lettering design is constrained, with a
							contemporary lineal base and contrasting Roman
							capitals. The logo achieves a perfect graphic
							balance between a traditional and a contemporary
							style, between heritage and progress. In such a way,
							it plays with the brand tension and is aesthetically
							in line with a refined graphic style.
						</p>
						<p>
							The similarities between the trades of baking and
							typography were a source of inspiration. A respect
							of tradition is strongly anchored in these two
							trades, which are also associated with a desire to
							innovate and reinvent themselves.
						</p>
					</div>
				</Column>

				<Column modifiers="right">
					<div className="column__text column__sticky text-content">
						<h3>The art of refining the material</h3>
						<p>
							The new brand presents an artistic vision of the use
							of the baker's ingredients, with each shape offering
							an artistic metaphor for the materials used.
						</p>
						<p>
							Flour, chocolate and dough are no longer raw
							materials, but are transformed into artistic
							materials.
						</p>
					</div>

					<div className="column__media text-content">
						<Image
							{...data.image_4.childImageSharp}
							alt="Duo Christophe texture"
						/>
						<Image
							{...data.image_5.childImageSharp}
							alt="Duo flour texture"
							className="grid-spacing"
						/>
						<Image
							{...data.image_6.childImageSharp}
							alt="Duo flute texture"
							className="grid-spacing"
						/>
					</div>
				</Column>

				<Image
					className="col-full"
					{...data.image_7.childImageSharp}
					alt="Pastry"
				/>

				<Blockquote
					modifiers="big"
					uptitle=""
					quote="The Ackermann identity presents an almost infinite palette of variations."
					more="The visual identity is inspired by the old packaging of traditional products, such as Iberian soaps, which were presented with a range of highly creative visuals while remaining coherent."
					cite=""
				/>

				{/* 12 col + [Image + Image + Image + Image] + gutter */}
				<Gallery className="col-full" modifiers="gutter">
					<Image {...data.image_8.childImageSharp} alt="Packaging" />
					<Image {...data.image_9.childImageSharp} alt="Packaging" />
				</Gallery>

				<Image
					className="col-full grid-spacing"
					{...data.image_10.childImageSharp}
					alt="Packaging"
				/>

				<Gallery className="col-full grid-spacing" modifiers="gutter">
					<Image {...data.image_11.childImageSharp} alt="Bread" />
					<Image {...data.image_12.childImageSharp} alt="Chocolate" />
				</Gallery>

				<Image
					className="col-full grid-spacing"
					{...data.image_13.childImageSharp}
					alt="Silk paper"
				/>

				<Blockquote
					modifiers="big"
					uptitle=""
					quote="Ackermann is about people, authenticity and passion. Behind each product are the collaborators and regional craftsmen who are at times not very visible but are committed to quality and the subtleties of taste."
					cite=""
					style={{ textAlign: 'center' }}
				/>

				<Column modifiers="right">
					<div className="column__text column__sticky text-content">
						<h3>Pictures speak louder than words</h3>
						<p>
							The photographic work aims to best represent the
							authenticity, simplicity and human warmth that is
							part of the bakery’s DNA.
						</p>
						<p>
							The composition is pure, with a minimalist approach
							that allows the subject to fully express itself.
						</p>
					</div>

					<div className="column__media text-content">
						<Image
							{...data.image_14.childImageSharp}
							alt="Team duo"
						/>
						<Image
							{...data.image_15.childImageSharp}
							alt="Team grid"
							className="grid-spacing"
						/>
						<Image
							{...data.image_16.childImageSharp}
							alt="Duo pastry bread"
							className="grid-spacing"
						/>
					</div>
				</Column>

				<Blockquote
					uptitle="Client testimonial"
					quote="« I have found in you an exceptional partner. You have helped me lay the foundation for a whole new chapter of positive strategic change. The result is absolutely brilliant and I am very proud to be teaming up with you. »"
					cite="Christophe Ackermann"
				/>

				<Image
					className="col-full grid-spacing"
					{...data.image_17.childImageSharp}
					alt="Smart"
				/>

				<Gallery className="col-full grid-spacing" modifiers="gutter">
					<Image {...data.image_18.childImageSharp} alt="Sac" />
					<Image
						{...data.image_19.childImageSharp}
						alt="Business cards"
					/>
				</Gallery>

				<Gallery className="col-full" modifiers="border">
					<Image {...data.image_20.childImageSharp} alt="Poster" />
					<Image {...data.image_21.childImageSharp} alt="Poster" />
				</Gallery>

				<Column modifiers="right">
					<div className="column__text column__sticky text-content">
						<h3>A custom designed typography for Ackermann</h3>
						<p>
							Discover the typography designed for Ackermann that
							highlights the strengths of what sets the brand
							apart as unique.
						</p>
						<ButtonSecondary
							href="/en/projects/ackermann-typography/"
							text="Ackermann handcrafted typography"
							data-target
						/>
					</div>

					<div className="column__media text-content">
						<Image
							{...data.image_22.childImageSharp}
							alt="Typography"
						/>
					</div>
				</Column>
			</section>
		</Layout>
	);
};

export default ProjectAckBrandPage;
